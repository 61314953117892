import React from 'react'
import Block from "../components/Block";

function Items() {
  return (
    <div className="content">
      <div className="wrapper">
        <div className="flex items">
          <Block>
            <span className="item item-1"><span className="item-number">1</span></span>
            <span className="item item-2"><span className="item-number">2</span></span>
            <span className="item item-3"><span className="item-number">3</span></span>
            <span className="item item-4"><span className="item-number">4</span></span>
            <span className="item item-5"><span className="item-number">5</span></span>
            <span className="item item-6"><span className="item-number">6</span></span>
            <span className="item item-7"><span className="item-number">7</span></span>
            <span className="item item-8"><span className="item-number">8</span></span>
            <span className="item item-9"><span className="item-number">9</span></span>
            <span className="item item-10"><span className="item-number">10</span></span>
            <span className="item item-11"><span className="item-number">11</span></span>
            <span className="item item-12"><span className="item-number">12</span></span>
            <span className="item item-13"><span className="item-number">13</span></span>
            <span className="item item-14"><span className="item-number">14</span></span>
            <span className="item item-15"><span className="item-number">15</span></span>
            <span className="item item-16"><span className="item-number">16</span></span>
            <span className="item item-17"><span className="item-number">17</span></span>
            <span className="item item-18"><span className="item-number">18</span></span>
            <span className="item item-19"><span className="item-number">19</span></span>
            <span className="item item-20"><span className="item-number">20</span></span>
            <span className="item item-21"><span className="item-number">21</span></span>
            <span className="item item-22"><span className="item-number">22</span></span>
            <span className="item item-23"><span className="item-number">23</span></span>
            <span className="item item-24"><span className="item-number">24</span></span>
            <span className="item item-25"><span className="item-number">25</span></span>
            <span className="item item-26"><span className="item-number">26</span></span>
            <span className="item item-27"><span className="item-number">27</span></span>
            <span className="item item-28"><span className="item-number">28</span></span>
            <span className="item item-29"><span className="item-number">29</span></span>
            <span className="item item-30"><span className="item-number">30</span></span>
            <span className="item item-31"><span className="item-number">31</span></span>
            <span className="item item-32"><span className="item-number">32</span></span>
            <span className="item item-33"><span className="item-number">33</span></span>
            <span className="item item-34"><span className="item-number">34</span></span>
            <span className="item item-35"><span className="item-number">35</span></span>
            <span className="item item-36"><span className="item-number">36</span></span>
            <span className="item item-37"><span className="item-number">37</span></span>
            <span className="item item-38"><span className="item-number">38</span></span>
            <span className="item item-39"><span className="item-number">39</span></span>
            <span className="item item-40"><span className="item-number">40</span></span>
            <span className="item item-41"><span className="item-number">41</span></span>
            <span className="item item-42"><span className="item-number">42</span></span>
            <span className="item item-43"><span className="item-number">43</span></span>
            <span className="item item-44"><span className="item-number">44</span></span>
            <span className="item item-45"><span className="item-number">45</span></span>
            <span className="item item-46"><span className="item-number">46</span></span>
            <span className="item item-47"><span className="item-number">47</span></span>
            <span className="item item-48"><span className="item-number">48</span></span>
            <span className="item item-49"><span className="item-number">49</span></span>
            <span className="item item-50"><span className="item-number">50</span></span>
            <span className="item item-51"><span className="item-number">51</span></span>
            <span className="item item-52"><span className="item-number">52</span></span>
            <span className="item item-53"><span className="item-number">53</span></span>
            <span className="item item-54"><span className="item-number">54</span></span>
            <span className="item item-55"><span className="item-number">55</span></span>
            <span className="item item-56"><span className="item-number">56</span></span>
            <span className="item item-57"><span className="item-number">57</span></span>
            <span className="item item-58"><span className="item-number">58</span></span>
            <span className="item item-59"><span className="item-number">59</span></span>
            <span className="item item-60"><span className="item-number">60</span></span>
            <span className="item item-61"><span className="item-number">61</span></span>
            <span className="item item-62"><span className="item-number">62</span></span>
            <span className="item item-63"><span className="item-number">63</span></span>
            <span className="item item-64"><span className="item-number">64</span></span>
            <span className="item item-65"><span className="item-number">65</span></span>
            <span className="item item-66"><span className="item-number">66</span></span>
            <span className="item item-67"><span className="item-number">67</span></span>
            <span className="item item-68"><span className="item-number">68</span></span>
            <span className="item item-69"><span className="item-number">69</span></span>
            <span className="item item-70"><span className="item-number">70</span></span>
            <span className="item item-71"><span className="item-number">71</span></span>
            <span className="item item-72"><span className="item-number">72</span></span>
            <span className="item item-73"><span className="item-number">73</span></span>
            <span className="item item-74"><span className="item-number">74</span></span>
            <span className="item item-75"><span className="item-number">75</span></span>
            <span className="item item-76"><span className="item-number">76</span></span>
            <span className="item item-77"><span className="item-number">77</span></span>
            <span className="item item-78"><span className="item-number">78</span></span>
            <span className="item item-79"><span className="item-number">79</span></span>
            <span className="item item-80"><span className="item-number">80</span></span>
            <span className="item item-81"><span className="item-number">81</span></span>
            <span className="item item-82"><span className="item-number">82</span></span>
            <span className="item item-83"><span className="item-number">83</span></span>
            <span className="item item-84"><span className="item-number">84</span></span>
            <span className="item item-85"><span className="item-number">85</span></span>
            <span className="item item-86"><span className="item-number">86</span></span>
            <span className="item item-87"><span className="item-number">87</span></span>
            <span className="item item-88"><span className="item-number">88</span></span>
            <span className="item item-89"><span className="item-number">89</span></span>
            <span className="item item-90"><span className="item-number">90</span></span>
            <span className="item item-91"><span className="item-number">91</span></span>
            <span className="item item-92"><span className="item-number">92</span></span>
            <span className="item item-93"><span className="item-number">93</span></span>
            <span className="item item-94"><span className="item-number">94</span></span>
            <span className="item item-95"><span className="item-number">95</span></span>
            <span className="item item-96"><span className="item-number">96</span></span>
            <span className="item item-97"><span className="item-number">97</span></span>
            <span className="item item-98"><span className="item-number">98</span></span>
            <span className="item item-99"><span className="item-number">99</span></span>
            <span className="item item-100"><span className="item-number">100</span></span>
            <span className="item item-101"><span className="item-number">101</span></span>
            <span className="item item-102"><span className="item-number">102</span></span>
            <span className="item item-103"><span className="item-number">103</span></span>
            <span className="item item-104"><span className="item-number">104</span></span>
            <span className="item item-105"><span className="item-number">105</span></span>
            <span className="item item-106"><span className="item-number">106</span></span>
            <span className="item item-107"><span className="item-number">107</span></span>
            <span className="item item-108"><span className="item-number">108</span></span>
            <span className="item item-109"><span className="item-number">109</span></span>
            <span className="item item-110"><span className="item-number">110</span></span>
            <span className="item item-111"><span className="item-number">111</span></span>
            <span className="item item-112"><span className="item-number">112</span></span>
            <span className="item item-113"><span className="item-number">113</span></span>
            <span className="item item-114"><span className="item-number">114</span></span>
            <span className="item item-115"><span className="item-number">115</span></span>
            <span className="item item-116"><span className="item-number">116</span></span>
            <span className="item item-117"><span className="item-number">117</span></span>
            <span className="item item-118"><span className="item-number">118</span></span>
            <span className="item item-119"><span className="item-number">119</span></span>
            <span className="item item-120"><span className="item-number">120</span></span>
            <span className="item item-121"><span className="item-number">121</span></span>
            <span className="item item-122"><span className="item-number">122</span></span>
            <span className="item item-123"><span className="item-number">123</span></span>
            <span className="item item-124"><span className="item-number">124</span></span>
            <span className="item item-125"><span className="item-number">125</span></span>
            <span className="item item-126"><span className="item-number">126</span></span>
            <span className="item item-127"><span className="item-number">127</span></span>
            <span className="item item-128"><span className="item-number">128</span></span>
            <span className="item item-129"><span className="item-number">129</span></span>
            <span className="item item-130"><span className="item-number">130</span></span>
            <span className="item item-131"><span className="item-number">131</span></span>
            <span className="item item-132"><span className="item-number">132</span></span>
            <span className="item item-133"><span className="item-number">133</span></span>
            <span className="item item-134"><span className="item-number">134</span></span>
            <span className="item item-135"><span className="item-number">135</span></span>
            <span className="item item-136"><span className="item-number">136</span></span>
            <span className="item item-137"><span className="item-number">137</span></span>
            <span className="item item-138"><span className="item-number">138</span></span>
            <span className="item item-139"><span className="item-number">139</span></span>
            <span className="item item-140"><span className="item-number">140</span></span>
            <span className="item item-141"><span className="item-number">141</span></span>
            <span className="item item-142"><span className="item-number">142</span></span>
            <span className="item item-143"><span className="item-number">143</span></span>
            <span className="item item-144"><span className="item-number">144</span></span>
            <span className="item item-145"><span className="item-number">145</span></span>
            <span className="item item-146"><span className="item-number">146</span></span>
            <span className="item item-147"><span className="item-number">147</span></span>
            <span className="item item-148"><span className="item-number">148</span></span>
            <span className="item item-149"><span className="item-number">149</span></span>
            <span className="item item-150"><span className="item-number">150</span></span>
            <span className="item item-151"><span className="item-number">151</span></span>
            <span className="item item-152"><span className="item-number">152</span></span>
            <span className="item item-153"><span className="item-number">153</span></span>
            <span className="item item-154"><span className="item-number">154</span></span>
            <span className="item item-155"><span className="item-number">155</span></span>
            <span className="item item-156"><span className="item-number">156</span></span>
            <span className="item item-157"><span className="item-number">157</span></span>
            <span className="item item-158"><span className="item-number">158</span></span>
            <span className="item item-159"><span className="item-number">159</span></span>
            <span className="item item-160"><span className="item-number">160</span></span>
            <span className="item item-161"><span className="item-number">161</span></span>
            <span className="item item-162"><span className="item-number">162</span></span>
            <span className="item item-163"><span className="item-number">163</span></span>
            <span className="item item-164"><span className="item-number">164</span></span>
            <span className="item item-165"><span className="item-number">165</span></span>
            <span className="item item-166"><span className="item-number">166</span></span>
            <span className="item item-167"><span className="item-number">167</span></span>
            <span className="item item-168"><span className="item-number">168</span></span>
            <span className="item item-169"><span className="item-number">169</span></span>
            <span className="item item-170"><span className="item-number">170</span></span>
            <span className="item item-171"><span className="item-number">171</span></span>
            <span className="item item-172"><span className="item-number">172</span></span>
            <span className="item item-173"><span className="item-number">173</span></span>
            <span className="item item-174"><span className="item-number">174</span></span>
            <span className="item item-175"><span className="item-number">175</span></span>
            <span className="item item-176"><span className="item-number">176</span></span>
            <span className="item item-177"><span className="item-number">177</span></span>
            <span className="item item-178"><span className="item-number">178</span></span>
            <span className="item item-179"><span className="item-number">179</span></span>
            <span className="item item-180"><span className="item-number">180</span></span>
            <span className="item item-181"><span className="item-number">181</span></span>
            <span className="item item-182"><span className="item-number">182</span></span>
            <span className="item item-183"><span className="item-number">183</span></span>
            <span className="item item-184"><span className="item-number">184</span></span>
            <span className="item item-185"><span className="item-number">185</span></span>
            <span className="item item-186"><span className="item-number">186</span></span>
            <span className="item item-187"><span className="item-number">187</span></span>
            <span className="item item-188"><span className="item-number">188</span></span>
            <span className="item item-189"><span className="item-number">189</span></span>
            <span className="item item-190"><span className="item-number">190</span></span>
            <span className="item item-191"><span className="item-number">191</span></span>
            <span className="item item-192"><span className="item-number">192</span></span>
            <span className="item item-193"><span className="item-number">193</span></span>
            <span className="item item-194"><span className="item-number">194</span></span>
            <span className="item item-195"><span className="item-number">195</span></span>
            <span className="item item-196"><span className="item-number">196</span></span>
            <span className="item item-197"><span className="item-number">197</span></span>
            <span className="item item-198"><span className="item-number">198</span></span>
            <span className="item item-199"><span className="item-number">199</span></span>
            <span className="item item-200"><span className="item-number">200</span></span>
            <span className="item item-201"><span className="item-number">201</span></span>
            <span className="item item-202"><span className="item-number">202</span></span>
            <span className="item item-203"><span className="item-number">203</span></span>
            <span className="item item-204"><span className="item-number">204</span></span>
            <span className="item item-205"><span className="item-number">205</span></span>
            <span className="item item-206"><span className="item-number">206</span></span>
            <span className="item item-207"><span className="item-number">207</span></span>
            <span className="item item-208"><span className="item-number">208</span></span>
            <span className="item item-209"><span className="item-number">209</span></span>
            <span className="item item-210"><span className="item-number">210</span></span>
            <span className="item item-211"><span className="item-number">211</span></span>
            <span className="item item-212"><span className="item-number">212</span></span>
            <span className="item item-213"><span className="item-number">213</span></span>
            <span className="item item-214"><span className="item-number">214</span></span>
            <span className="item item-215"><span className="item-number">215</span></span>
            <span className="item item-216"><span className="item-number">216</span></span>
            <span className="item item-217"><span className="item-number">217</span></span>
            <span className="item item-218"><span className="item-number">218</span></span>
            <span className="item item-219"><span className="item-number">219</span></span>
            <span className="item item-220"><span className="item-number">220</span></span>
            <span className="item item-221"><span className="item-number">221</span></span>
            <span className="item item-222"><span className="item-number">222</span></span>
            <span className="item item-223"><span className="item-number">223</span></span>
            <span className="item item-224"><span className="item-number">224</span></span>
            <span className="item item-225"><span className="item-number">225</span></span>
            <span className="item item-226"><span className="item-number">226</span></span>
            <span className="item item-227"><span className="item-number">227</span></span>
            <span className="item item-228"><span className="item-number">228</span></span>
            <span className="item item-229"><span className="item-number">229</span></span>
            <span className="item item-230"><span className="item-number">230</span></span>
            <span className="item item-231"><span className="item-number">231</span></span>
            <span className="item item-232"><span className="item-number">232</span></span>
            <span className="item item-233"><span className="item-number">233</span></span>
            <span className="item item-234"><span className="item-number">234</span></span>
            <span className="item item-235"><span className="item-number">235</span></span>
            <span className="item item-236"><span className="item-number">236</span></span>
            <span className="item item-237"><span className="item-number">237</span></span>
            <span className="item item-238"><span className="item-number">238</span></span>
            <span className="item item-239"><span className="item-number">239</span></span>
            <span className="item item-240"><span className="item-number">240</span></span>
            <span className="item item-241"><span className="item-number">241</span></span>
            <span className="item item-242"><span className="item-number">242</span></span>
            <span className="item item-243"><span className="item-number">243</span></span>
            <span className="item item-244"><span className="item-number">244</span></span>
            <span className="item item-245"><span className="item-number">245</span></span>
            <span className="item item-246"><span className="item-number">246</span></span>
            <span className="item item-247"><span className="item-number">247</span></span>
            <span className="item item-248"><span className="item-number">248</span></span>
            <span className="item item-249"><span className="item-number">249</span></span>
            <span className="item item-250"><span className="item-number">250</span></span>
            <span className="item item-251"><span className="item-number">251</span></span>
            <span className="item item-252"><span className="item-number">252</span></span>
            <span className="item item-253"><span className="item-number">253</span></span>
            <span className="item item-254"><span className="item-number">254</span></span>
            <span className="item item-255"><span className="item-number">255</span></span>
            <span className="item item-256"><span className="item-number">256</span></span>
            <span className="item item-257"><span className="item-number">257</span></span>
            <span className="item item-258"><span className="item-number">258</span></span>
            <span className="item item-259"><span className="item-number">259</span></span>
            <span className="item item-260"><span className="item-number">260</span></span>
            <span className="item item-261"><span className="item-number">261</span></span>
            <span className="item item-262"><span className="item-number">262</span></span>
            <span className="item item-263"><span className="item-number">263</span></span>
            <span className="item item-264"><span className="item-number">264</span></span>
            <span className="item item-265"><span className="item-number">265</span></span>
            <span className="item item-266"><span className="item-number">266</span></span>
            <span className="item item-267"><span className="item-number">267</span></span>
            <span className="item item-268"><span className="item-number">268</span></span>
            <span className="item item-269"><span className="item-number">269</span></span>
            <span className="item item-270"><span className="item-number">270</span></span>
            <span className="item item-271"><span className="item-number">271</span></span>
            <span className="item item-272"><span className="item-number">272</span></span>
            <span className="item item-273"><span className="item-number">273</span></span>
            <span className="item item-274"><span className="item-number">274</span></span>
            <span className="item item-275"><span className="item-number">275</span></span>
            <span className="item item-276"><span className="item-number">276</span></span>
            <span className="item item-277"><span className="item-number">277</span></span>
            <span className="item item-278"><span className="item-number">278</span></span>
            <span className="item item-279"><span className="item-number">279</span></span>
            <span className="item item-280"><span className="item-number">280</span></span>
            <span className="item item-281"><span className="item-number">281</span></span>
            <span className="item item-282"><span className="item-number">282</span></span>
            <span className="item item-283"><span className="item-number">283</span></span>
            <span className="item item-284"><span className="item-number">284</span></span>
            <span className="item item-285"><span className="item-number">285</span></span>
            <span className="item item-286"><span className="item-number">286</span></span>
            <span className="item item-287"><span className="item-number">287</span></span>
            <span className="item item-288"><span className="item-number">288</span></span>
            <span className="item item-289"><span className="item-number">289</span></span>
            <span className="item item-290"><span className="item-number">290</span></span>
            <span className="item item-291"><span className="item-number">291</span></span>
            <span className="item item-292"><span className="item-number">292</span></span>
            <span className="item item-293"><span className="item-number">293</span></span>
            <span className="item item-294"><span className="item-number">294</span></span>
            <span className="item item-295"><span className="item-number">295</span></span>
            <span className="item item-296"><span className="item-number">296</span></span>
            <span className="item item-297"><span className="item-number">297</span></span>
            <span className="item item-298"><span className="item-number">298</span></span>
            <span className="item item-299"><span className="item-number">299</span></span>
            <span className="item item-300"><span className="item-number">300</span></span>
            <span className="item item-301"><span className="item-number">301</span></span>
            <span className="item item-302"><span className="item-number">302</span></span>
            <span className="item item-303"><span className="item-number">303</span></span>
            <span className="item item-304"><span className="item-number">304</span></span>
            <span className="item item-305"><span className="item-number">305</span></span>
            <span className="item item-306"><span className="item-number">306</span></span>
            <span className="item item-307"><span className="item-number">307</span></span>
            <span className="item item-308"><span className="item-number">308</span></span>
            <span className="item item-309"><span className="item-number">309</span></span>
            <span className="item item-310"><span className="item-number">310</span></span>
            <span className="item item-311"><span className="item-number">311</span></span>
            <span className="item item-312"><span className="item-number">312</span></span>
            <span className="item item-313"><span className="item-number">313</span></span>
            <span className="item item-314"><span className="item-number">314</span></span>
            <span className="item item-315"><span className="item-number">315</span></span>
            <span className="item item-316"><span className="item-number">316</span></span>
            <span className="item item-317"><span className="item-number">317</span></span>
            <span className="item item-318"><span className="item-number">318</span></span>
            <span className="item item-319"><span className="item-number">319</span></span>
            <span className="item item-320"><span className="item-number">320</span></span>
            <span className="item item-321"><span className="item-number">321</span></span>
            <span className="item item-322"><span className="item-number">322</span></span>
            <span className="item item-323"><span className="item-number">323</span></span>
            <span className="item item-324"><span className="item-number">324</span></span>
            <span className="item item-325"><span className="item-number">325</span></span>
            <span className="item item-326"><span className="item-number">326</span></span>
            <span className="item item-327"><span className="item-number">327</span></span>
            <span className="item item-328"><span className="item-number">328</span></span>
            <span className="item item-329"><span className="item-number">329</span></span>
            <span className="item item-330"><span className="item-number">330</span></span>
            <span className="item item-331"><span className="item-number">331</span></span>
            <span className="item item-332"><span className="item-number">332</span></span>
            <span className="item item-333"><span className="item-number">333</span></span>
            <span className="item item-334"><span className="item-number">334</span></span>
            <span className="item item-335"><span className="item-number">335</span></span>
            <span className="item item-336"><span className="item-number">336</span></span>
            <span className="item item-337"><span className="item-number">337</span></span>
            <span className="item item-338"><span className="item-number">338</span></span>
            <span className="item item-339"><span className="item-number">339</span></span>
            <span className="item item-340"><span className="item-number">340</span></span>
            <span className="item item-341"><span className="item-number">341</span></span>
            <span className="item item-342"><span className="item-number">342</span></span>
            <span className="item item-343"><span className="item-number">343</span></span>
            <span className="item item-344"><span className="item-number">344</span></span>
            <span className="item item-345"><span className="item-number">345</span></span>
            <span className="item item-346"><span className="item-number">346</span></span>
            <span className="item item-347"><span className="item-number">347</span></span>
            <span className="item item-348"><span className="item-number">348</span></span>
            <span className="item item-349"><span className="item-number">349</span></span>
            <span className="item item-350"><span className="item-number">350</span></span>
            <span className="item item-351"><span className="item-number">351</span></span>
            <span className="item item-352"><span className="item-number">352</span></span>
            <span className="item item-353"><span className="item-number">353</span></span>
            <span className="item item-354"><span className="item-number">354</span></span>
            <span className="item item-355"><span className="item-number">355</span></span>
            <span className="item item-356"><span className="item-number">356</span></span>
            <span className="item item-357"><span className="item-number">357</span></span>
            <span className="item item-358"><span className="item-number">358</span></span>
            <span className="item item-359"><span className="item-number">359</span></span>
            <span className="item item-360"><span className="item-number">360</span></span>
            <span className="item item-361"><span className="item-number">361</span></span>
            <span className="item item-362"><span className="item-number">362</span></span>
            <span className="item item-363"><span className="item-number">363</span></span>
            <span className="item item-364"><span className="item-number">364</span></span>
            <span className="item item-365"><span className="item-number">365</span></span>
            <span className="item item-366"><span className="item-number">366</span></span>
            <span className="item item-367"><span className="item-number">367</span></span>
            <span className="item item-368"><span className="item-number">368</span></span>
            <span className="item item-369"><span className="item-number">369</span></span>
            <span className="item item-370"><span className="item-number">370</span></span>
            <span className="item item-371"><span className="item-number">371</span></span>
            <span className="item item-372"><span className="item-number">372</span></span>
            <span className="item item-373"><span className="item-number">373</span></span>
            <span className="item item-374"><span className="item-number">374</span></span>
            <span className="item item-375"><span className="item-number">375</span></span>
            <span className="item item-376"><span className="item-number">376</span></span>
            <span className="item item-377"><span className="item-number">377</span></span>
            <span className="item item-378"><span className="item-number">378</span></span>
            <span className="item item-379"><span className="item-number">379</span></span>
            <span className="item item-380"><span className="item-number">380</span></span>
            <span className="item item-381"><span className="item-number">381</span></span>
            <span className="item item-382"><span className="item-number">382</span></span>
            <span className="item item-383"><span className="item-number">383</span></span>
            <span className="item item-384"><span className="item-number">384</span></span>
            <span className="item item-385"><span className="item-number">385</span></span>
            <span className="item item-386"><span className="item-number">386</span></span>
            <span className="item item-387"><span className="item-number">387</span></span>
            <span className="item item-388"><span className="item-number">388</span></span>
            <span className="item item-389"><span className="item-number">389</span></span>
            <span className="item item-390"><span className="item-number">390</span></span>
            <span className="item item-391"><span className="item-number">391</span></span>
            <span className="item item-392"><span className="item-number">392</span></span>
            <span className="item item-393"><span className="item-number">393</span></span>
            <span className="item item-394"><span className="item-number">394</span></span>
            <span className="item item-395"><span className="item-number">395</span></span>
            <span className="item item-396"><span className="item-number">396</span></span>
            <span className="item item-397"/>
            <span className="item item-398"/>
            <span className="item item-399"/>
            <span className="item item-400"/>
          </Block>
        </div>
      </div>
    </div>
  )
}

export default Items