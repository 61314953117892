import React from 'react';
import helper from "../helper";

const CraftSubItem = ({ item, craftItems, showDeep, level = 0, multiplier = 1 }) => {
  if (!item) return null;

  function findItem(itemName) {
    for (let i in craftItems) {
      if (craftItems[i].itemName === itemName) {
        return craftItems[i];
      }
    }
    return null;
  }

  if (!item.consumes || item.consumes.length === 0) {
    return null;
  }

  return (
    <div className="craft-sub-items">
      {item.consumes && item.consumes.map(consumeItemShort => {
        const consumeItem = findItem(consumeItemShort.itemName);
        if (!consumeItem) {
          return null;
        }
        if (consumeItem.itemName === item.itemName) {
          return null;
        }
        let style = helper.getItemIconStyle(consumeItem);

        return (
          <div key={item.itemName + ' ' + consumeItem.itemName} className="craft-sub-item" title={consumeItem.displayName}>
            <span className="item" style={style}/>
            <span>x </span>
            <span>{multiplier * consumeItemShort.quantity}</span>
            {consumeItem.shop === 'viper' && <span> (Viper)</span>}
            {showDeep &&
              <div className="craft-sub-item-deep">
                <CraftSubItem
                  item={consumeItem}
                  craftItems={craftItems}
                  level={level + 1}
                  showDeep={showDeep}
                  multiplier={multiplier * consumeItemShort.quantity} />
              </div>
            }
          </div>
        );
      })}
    </div>
  );
};

export default CraftSubItem;