import React, { useState } from 'react';

function SearchItems({ items, onItemSelected }) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredItems = items.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectItem = (item) => {
    onItemSelected(item);
  };

  return (
    <div className="search-items">
      <input
        className="search-input text-field"
        type="text"
        placeholder="Search items..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <ul className="search-items-list">
        {filteredItems.map(item => (
          <li
            key={item.id}
            className="search-item"
            onClick={() => handleSelectItem(item)}
          >
            <span className={'item ' + item.css}/><span>{item.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SearchItems;
