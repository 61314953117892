import React, { useEffect, useState } from 'react';
import Block from "../components/Block";
import SearchItems from "../components/SearchItems";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCopy, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import Modal from "../components/Modal";

const tradeItemTemplate = {
  item: null,
  quantity: 1,
};

function Trade() {
  const [tradableItems, setTradableItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tradeDirection, setTradeDirection] = useState('buy');
  const [isUrlParamsHandled, setUrlParamsHandled] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [priceInDollar, setPriceInDollar] = useState(0.00);
  // const [priceInGold, setPriceInGold] = useState(0.00);

  useEffect(() => {
    if (isUrlParamsHandled || tradableItems.length === 0) {
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const directionFromUrl = queryParams.get('direction');
    if (directionFromUrl) {
      setTradeDirection(directionFromUrl);
    }
    const itemsFromUrl = [];
    queryParams.forEach((value, key) => {
      if (key.startsWith('id_')) {
        const itemId = parseInt(key.substring(3), 10);
        const quantity = parseInt(value, 10);
        const item = tradableItems.find(item => item.id === itemId);
        if (item) {
          itemsFromUrl.push({ item, quantity });
        }
      }
    });
    if (itemsFromUrl.length > 0) {
      setSelectedItems(itemsFromUrl);
    }
    setUrlParamsHandled(true);
  }, [tradableItems]);

  useEffect(() => {
    if (document.config && document.config.items) {
      const filteredItems = document.config.items.filter(item => {
        if (!item.is_tradable || !item.label) {
          return false;
        }
        if (tradeDirection === 'buy' && !item.is_buying_now) {
          return false;
        }
        if (tradeDirection === 'sell' && !item.is_selling_now) {
          return false;
        }
        return true;
      });
      setTradableItems(filteredItems);
    }
  }, [tradeDirection]);

  useEffect(() => {
    const priceItemKey = tradeDirection === 'buy' ? 'buy_price' : 'sell_price';
    let dollarTotal = 0;
    for (let selectedItem of selectedItems) {
      dollarTotal += selectedItem.item[priceItemKey] * selectedItem.quantity;
    }
    dollarTotal = parseFloat(dollarTotal.toFixed(2));
    setPriceInDollar(dollarTotal);

    // // TODO: !!! incorrect buy/sell prices, need to change values or logic
    // const priceGoldKey = tradeDirection === 'buy' ? 'gold_buy_price' : 'gold_sell_price';
    // let goldTotal = dollarTotal / document.config.config[priceGoldKey];
    // goldTotal = parseFloat(goldTotal.toFixed(2));
    // setPriceInGold(goldTotal);

    if (isUrlParamsHandled) {
      const queryParams = new URLSearchParams();
      queryParams.set('direction', tradeDirection);
      selectedItems.forEach(item => {
        queryParams.set(`id_${item.item.id}`, item.quantity);
      });
      window.history.replaceState({}, '', `?${queryParams.toString()}`);
    }
  }, [selectedItems, tradeDirection]);

  const handleItemSelected = (item) => {
    const isItemAlreadySelected = selectedItems.some(selectedItem => selectedItem.item.id === item.id);
    if (!isItemAlreadySelected) {
      const newTradeItem = {
        ...tradeItemTemplate,
        item: item,
        quantity: parseInt(item.quantity_step)
      };
      const newItems = [...selectedItems, newTradeItem];
      setSelectedItems(newItems);
    }
  };

  const removeItem = (itemId) => {
    const filteredItems = selectedItems.filter(tradeItem => tradeItem.item.id !== itemId);
    setSelectedItems(filteredItems);
  };

  const handleQuantityChange = (itemId, quantity) => {
    const newSelectedItems = selectedItems.map(tradeItem => {
      if (tradeItem.item.id === itemId) {
        return {...tradeItem, quantity: parseInt(quantity)};
      }
      return tradeItem;
    });
    setSelectedItems(newSelectedItems);
  };

  const copyUrlToClipboard = () => {
    const url = window.location.href;

    navigator.clipboard.writeText(url).then(() => {
      toast.success('Copied', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }).catch(err => {
      toast.error('Can not copy, try to copy website url manually', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    });
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="content">
      <div className="wrapper">
        <div className="flex">
          <Block>
            <div className="row">
              <div className="col-xs-6 buy-sell-choose-block">
                <div className="buy-sell-choose">
                  <div className="radio-neon-wrapper">
                    <label>
                      <input
                        type="radio"
                        name="tradeDirection"
                        checked={tradeDirection === 'buy'}
                        onChange={() => setTradeDirection('buy')}
                      />
                      <span className="radio-neon">BUY</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="tradeDirection"
                        checked={tradeDirection === 'sell'}
                        onChange={() => setTradeDirection('sell')}
                      />
                      <span className="radio-neon">SELL</span>
                    </label>
                    <button onClick={openModal} className="question-button"><FontAwesomeIcon icon={faQuestion}/></button>
                  </div>
                </div>

              </div>
              <div className="col-xs-6 trade-total-block">
                <div className="trade-total-wrapper">
                  <div className="trade-total">
                    <div className="trade-total-money-wrapper">
                      <span className="dollar-sign">$</span>
                      <span className="trade-total-money text-field">{priceInDollar}</span>
                    </div>
                    {/*<div className="trade-total-gold-wrapper">*/}
                    {/*  <span className="item item-23"/>*/}
                    {/*  <span className="trade-total-gold text-field">{priceInGold}</span>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="trade-content">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <SearchItems items={tradableItems} onItemSelected={handleItemSelected} />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <div className="trade-items">
                        {selectedItems && selectedItems.map((tradeItem) => {
                          return (
                            <div className="trade-item" key={tradeItem.item.id}>
                              <span className={'item ' + tradeItem.item.css}/>
                              <input
                                className="text-field"
                                value={tradeItem.quantity}
                                onChange={(e) => handleQuantityChange(tradeItem.item.id, e.target.value)}
                                min={tradeItem.item.quantity_step}
                                type="number"
                                step={tradeItem.item.quantity_step}
                              />
                              <div
                                className="trade-item-remove"
                                onClick={() => removeItem(tradeItem.item.id)}
                              >
                                <FontAwesomeIcon icon={faTrash}/>
                              </div>
                            </div>
                          );
                        })}
                        {selectedItems && selectedItems.length > 0 &&
                          <div className="share-button-wrapper">
                            <button onClick={copyUrlToClipboard} className="share-button button">Share <FontAwesomeIcon icon={faCopy}/></button>
                          </div>
                        }
                        {selectedItems.length === 0 &&
                          <div className="trade-info">
                            <h3 className="trade-info-text">Selected items will appear here</h3>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <h2 className="modal-h2">How to</h2>
              <ul className="how-to-list">
                <li>Select buy or sell</li>
                <li>Select items</li>
                <li>Set quantity</li>
                <li>Press <b>share</b> button</li>
                <li>Send the link to <b>potatofan</b> on discord</li>
              </ul>
            </Modal>
          </Block>
        </div>
      </div>
    </div>
  )
}

export default Trade