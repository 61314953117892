import React from 'react';
import PropTypes from 'prop-types';

function Block({ children, width }) {
  const style = {
    width: width || '100%',
  };

  return (
    <div className="block" style={style}>
      {children}
    </div>
  );
}

Block.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Block;
