const Helper = {
  getItemIconStyle(item) {
    if (!item) {
      return {};
    }
    return {
      backgroundPosition: '-' + item.coordinates.x + 'px -' + item.coordinates.y + 'px',
    };
  }
}

export default Helper