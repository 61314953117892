import React from 'react'
import Block from "../components/Block";

function About() {
  return (
    <div className="content">
      <div className="wrapper">
        <div className="flex">
          <Block>
            <div className="center about-text-wrapper">
              <p>This site is dedicated to the game Soulbound (formerly WorldWideWebb). The site is created by the community on a voluntary basis. Before trusting the item list or any information, please double-check its relevance in the game and from official sources, as there may be inaccuracies and discrepancies with the current version of the game.</p>
              <br/>
              <p>Game</p>
              <p><a target="_blank" href="https://play.webb.game/">https://play.webb.game</a></p>
              <br/>
              <p>Webb Technology Site</p>
              <p><a target="_blank"  href="https://webb.game/">https://webb.game</a></p>
              <br/>
              <p>Soulbound Site</p>
              <p><a target="_blank"  href="https://soulbound.game/">https://soulbound.game</a></p>
              <br/>
              <p>If you want to help or donate to the creator of this site, just go to the game and kick some robot butts.</p>
              <br/>
            </div>
            <div className="center">
              <p><b>Developed by</b></p>
              <p><span className="authors-text">Potato <span className="authors-text-fade-1">Fan</span></span></p>
            </div>
            <br/>
            <div className="center">
              <p><b>Special thanks to</b></p>
              <p><span className="authors-text">Zank<span className="authors-text-fade-2">e</span>r</span></p>
              <p><span className="authors-text">Dim<span className="authors-text-fade-3">o</span>n</span></p>
              <p><span className="authors-text"><span className="authors-text-fade-4">Mr</span>Snorch</span></p>
              <p><span className="authors-text">Zo<span className="authors-text-fade-2">zu</span>l</span></p>
              <p><span className="authors-text"><span className="authors-text-fade-3">P</span>olex</span></p>
            </div>
          </Block>
        </div>
      </div>
    </div>
  )
}

export default About